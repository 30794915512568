import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="auto-save"
export default class extends Controller {
  static targets = ["indicator"];

  initialize() {
    this.focusedElement = null;
    this.focusedElementStart = null;
    this.focusedElementEnd = null;
  }

  connect() {
    if (this.element.tagName !== "FORM") {
      throw new Error("Auto-Save element must be a form");
    }

    this.element
      .querySelectorAll(
        [
          'input[type="text"]',
          'input[type="date"]',
          'input[type="time"]',
          "textarea"
        ].join(","),
      ).forEach((el) => el.addEventListener("focusout", this.save.bind(this)));

    this.element
      .querySelectorAll(
        [
          'input[type="checkbox"]',
          "select",
        ].join(","),
      ).forEach((el) => el.addEventListener("change", this.save.bind(this)));

    document.addEventListener("turbo:before-stream-render", (e) => {
      const original = e.detail.render;

      e.detail.render = async (currentElement) => {
        original(currentElement);

        if (currentElement?.target === this.element?.id) {
          if (this.focusedElement) {
            const newEl = document.getElementById(this.focusedElement.id)
            if (newEl) {
              newEl.focus();
              if (this.focusedElementStart) {
                newEl.setSelectionRange(this.focusedElementStart, this.focusedElementEnd);
              }
            }
            this.focusedElement = null;
            this.focusedElementStart = null;
            this.focusedElementEnd = null;
          }
        }
      };
    });
  }

  disconnect() {
    if (this.timeout) clearTimeout(this.timeout);
  }

  save(e) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    // setTimeout is required to give time to document.activeElement to be updated
    this.timeout = setTimeout(() => {
      if (this.indicatorTarget) {
        this.indicatorTarget.innerHTML = `<div class="text-warning">
                                          <i class="fa-solid fa-save me-1">
                                          ${this.indicatorTarget.dataset.text}
                                        </div>`;
      }

      console.log(document.activeElement)

      if (document.activeElement?.id) {
        this.focusedElement = document.activeElement;
        this.focusedElementStart = this.focusedElement?.selectionStart;
        this.focusedElementEnd = this.focusedElement?.selectionEnd;
      }

      const form = this.element;
      form.querySelector('input[type="submit"]')?.setAttribute("disabled", "disabled");

      const input = document.createElement("input");
      input.classList.add("d-none");
      input.setAttribute("name", "autosave");
      input.setAttribute("value", true);

      form.appendChild(input);
      form.requestSubmit();
    }, 0);
  }
}
